import {useAuthStore} from '../store/auth';
import axios from './axios';
import Cookies from 'js-cookie';
import { jwtDecode } from "jwt-decode";

export const login = async (nickname, password) => {
    //try {
        const { data, status } = await axios().post('login', {
            nickname,
            password,
        });
        if (status === 200) {
            const {authorisation} = data;
            setAuthUser(authorisation.token);
        }
        return { data, error: null };
    /*} catch (error) {
        return {
            data: null,
            error: 'Something went wrong',
        };
    }*/
};


export const logout = () => {
    Cookies.remove('Seznamovak_token');
    useAuthStore.getState().setUser(null);
};

export const setUser = async () => {
    // ON PAGE LOAD
    const accessToken = Cookies.get('Seznamovak_token');

    if (!accessToken) {
        return;
    }
    if (isAccessTokenExpired(accessToken)) {
        logout();
    } else {
        setAuthUser(accessToken);
    }
};

export const setAuthUser = (token) => {


    Cookies.set('Seznamovak_token', token, {
        expires: 10,
        secure: true,
    });

    const user = jwtDecode(token) ?? null;


    console.log(user)

    if (user) {

        useAuthStore.getState().setUser({...user, token:token});
    }
    useAuthStore.getState().setLoading(false);
};

export const getRefreshToken = async () => {
    const refresh_token = Cookies.get('Seznamovak_token');
    let response = null;
    try {
        response = await axios().post('token/refresh/' , {
            refresh :refresh_token ,
        })
    } catch (error) {
        logout();
        return;
    }

    return response.data;

};

export const isAccessTokenExpired = () => {
    const token = Cookies.get('Seznamovak_token');
    try {
        const decodedToken = jwtDecode(token);
        return decodedToken.exp < Date.now() / 1000;
    } catch (err) {
        return true; // Token is invalid or expired
    }
};