import {Link, Navigate} from 'react-router-dom';
import { useAuthStore } from '../store/auth';
import {GridExample}from '../utils/table2'
import {getData} from "../utils/arth";
import {useEffect} from "react";
import './my_styles/Confetti.css';


const Home = () => {
    const [isLoggedIn, user] = useAuthStore((state) => [
        state.isLoggedIn,
        state.user
    ]);
    useEffect(() => {
        // Funkce pro vytvoření konfet
        const createConfetti = () => {
            const confettiContainer = document.getElementById('confetti-container');
            const confetti = document.createElement('div');
            confetti.className = 'confetti';
            confetti.style.setProperty('--random-x', Math.random()); // Náhodná horizontální pozice
            confetti.style.setProperty('--random-y', Math.random()); // Náhodná vertikální pozice
            confetti.style.backgroundColor = '#' + Math.floor(Math.random()*16777215).toString(16); // Náhodná barva
            confettiContainer.appendChild(confetti);
        };

        // Vytvoříme konfety
        for (let i = 0; i < 100; i++) {
            createConfetti();
        }
    }, []); // Použití useEffect pro spuštění kódu při načtení komponenty

    return (<>
            <div id="confetti-container"/>
            <div style={{textAlign: 'center', maxWidth: '600px', margin: '0 auto', padding: '20px'}}>
                <h1 style={{fontFamily: 'Arial, sans-serif', color: '#333333', fontSize: '24px'}}>Vítej {user().nickname}</h1>
                <p style={{fontFamily: 'Arial, sans-serif', color: '#666666', fontSize: '16px', lineHeight: '1.5'}}>Vývoj adminu stále probíhá, proto pokud najdeš chybu nebo máš nápad na jeho vylepšení, neboj se ozvat.</p>


            </div></>
    );
};

export default Home;