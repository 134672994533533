
import axios from "./axios";
import {Navigate} from "react-router-dom";
import Cookies from "js-cookie";


export const getData = async (URL) => {
    try {
        // Získanie JWT tokenu z lokálneho úložiska
        const token = Cookies.get('Seznamovak_token');

        // Ak token neexistuje, presmerujte používateľa na stránku s prihlásením
        if (!token) {
            return  <Navigate to="/" />;

        }

        const { data, status } = await axios().get(URL);


        return [status,data];
    } catch (error) {
        return -1,error;
    }
};


export const postData = async (URL,sendData) => {
    try {
        // Získanie JWT tokenu z lokálneho úložiska
        const token = Cookies.get('Seznamovak_token');

        // Ak token neexistuje, presmerujte používateľa na stránku s prihlásením
        if (!token) {
            return  <Navigate to="/" />;

        }

        const { data, status } = await axios().post(URL,{...sendData});


        return [status,data];
    } catch (error) {
        return -1,error;
    }
};

export const putData = async (URL,sendData) => {
    try {
        // Získanie JWT tokenu z lokálneho úložiska
        const token = Cookies.get('Seznamovak_token');

        // Ak token neexistuje, presmerujte používateľa na stránku s prihlásením
        if (!token) {
            return  <Navigate to="/" />;

        }

        const { data, status } = await axios().post(URL,{...sendData, _method: 'PUT'});


        return [status,data];
    } catch (error) {
        return -1,error;
    }
};


export const deleteData = async (URL) => {
    try {
        // Získanie JWT tokenu z lokálneho úložiska
        const token = Cookies.get('Seznamovak_token');

        // Ak token neexistuje, presmerujte používateľa na stránku s prihlásením
        if (!token) {
            return  <Navigate to="/" />;

        }

        const { data, status } = await axios().delete(URL);


        return [status,data];
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};


