import React, { useState, useEffect } from 'react';
import {BrowserRouter, Routes, Route, Link, Navigate} from 'react-router-dom';
import PrivateRoute from "./layouts/PrivateRoute";
import Login from './views/login';
import Home from "./views/home";
import Logout from "./views/logout";
import Users from "./views/users";
import { useAuthStore} from './store/auth';
import { setUser} from './utils/auth';
import { ProSidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { useMediaQuery } from '@mui/material';
import {
    FaCog,
    FaMoneyCheck,
    FaPaperPlane,
    FaSignOutAlt,
    FaUser, FaUserPlus,
    FaRegFileAlt
} from 'react-icons/fa';
import ErrPopUp from "./utils/ErrPopUp";
import Settings from "./views/settings";
import Profile from "./views/profile";
import Payment from "./views/payment";
import 'react-pro-sidebar/dist/css/styles.css';
import logo from './seznamovak_logo.svg';
import './App.css';
import Customers from "./views/customers";

function App() {
    const isMobile = useMediaQuery('(max-width: 768px)');

    const [isLoggedIn, user] = useAuthStore((state) => [
        state.isLoggedIn,
        state.user
    ]);

    const [successMessage, setSuccessMessage] = useState(null);
    const [error, setError] = useState('CHYBA');
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    useEffect(() => {
        const initializeUser = async () => {
            try {
                await setUser();
            } catch (error) {
                console.error('Error initializing user:', error);
                setError(error);
            }
        };

        initializeUser();
    }, []);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const openError = (errorMessage,relocate=null) => {
        setError(errorMessage);
    }

    const closeError = () => {
        setError(null);
        handleSuccessMessage("AHOOJ");
    }

    const handleSuccessMessage = (message) => {
        setSuccessMessage(message);
        setTimeout(() => {
            setSuccessMessage(null);
        }, 3000);
    };

    if (isMobile) {
        return <div style={{ textAlign: 'center', padding: '20px', fontSize: '18px', color: '#333', backgroundColor: '#f8f8f8' }}>Web aktuálně nepodporuje mobilní zařízení</div>;
    }
    return (
        <BrowserRouter>
            <div className="container">
                {isLoggedIn() && (
                <div className={`sidebar ${isSidebarOpen ? 'sidebar-open' : ''}`}>
                    <button className="menu-toggle" onClick={toggleSidebar}>☰</button>




                        <ProSidebar>
                            <button className="menu-toggle" onClick={toggleSidebar}>☰</button>
                            <img src={logo} alt="Logo" />
                            <Menu iconShape="square">
                                <MenuItem icon={<FaPaperPlane />}><Link to="/">Novinky</Link></MenuItem>
                                <MenuItem icon={<FaUser />}><Link to="/myProfile">Můj Profil</Link></MenuItem>
                                { user().competences?.includes('customer_read') && <MenuItem icon={<FaRegFileAlt/>}><Link to="/customer">Prihlášky</Link></MenuItem>}
                                { user().competences?.includes('payment_read') && <MenuItem icon={<FaMoneyCheck />}><Link to="/payment">Platby</Link></MenuItem>}
                                { user().competences?.includes('user_read') &&   <MenuItem icon={<FaUserPlus />}><Link to="/user">Uživatelé</Link></MenuItem>}
                                { user().competences?.includes('setting_read') && <MenuItem icon={<FaCog />}><Link to="/settings">Nastavení</Link></MenuItem>}
                            </Menu>

                            <div className="logout-menu-wrapper">
                                <Link to="/logout" >
                                <MenuItem icon={<FaSignOutAlt />} className="logout-menu">
                                    Odhlášení
                                </MenuItem>

                                </Link>
                            </div>
                        </ProSidebar>

                </div>)}
                <div className="content" style={!isLoggedIn() ? { marginLeft: '0px' } : {}}>
                    {error && <ErrPopUp errorMessage={error} closeMessage={closeError} />}
                    <Routes>
                        <Route path="/" element={<PrivateRoute><Home showError={openError} showSuccess={handleSuccessMessage} /></PrivateRoute>} />
                        <Route path="/myProfile" element={<PrivateRoute><Profile showError={openError} showSuccess={handleSuccessMessage}/></PrivateRoute>} />
                        { user().competences?.includes('customer_read') && <Route path="/customer" element={<PrivateRoute><Customers showError={openError} showSuccess={handleSuccessMessage}/></PrivateRoute>} />}
                        { user().competences?.includes('payment_read') && <Route path="/payment" element={<PrivateRoute><Payment showError={openError} showSuccess={handleSuccessMessage} /></PrivateRoute>} />}
                        <Route path="/login" element={<Login  />} />
                        <Route path="/logout" element={<Logout />} />
                        { user().competences?.includes('user_read') && <Route path="/user" element={<PrivateRoute><Users showError={openError} showSuccess={handleSuccessMessage}/></PrivateRoute>} />}
                        { user().competences?.includes('setting_read') &&  <Route path="/settings" element={<PrivateRoute><Settings showError={openError} showSuccess={handleSuccessMessage}/></PrivateRoute>} />}
                        <Route path="*" element={<Navigate to="/" replace />} />
                    </Routes>
                </div>
            </div>
            {successMessage && (
                <div className="success-message">
                    <p style={{
                        fontWeight: 'bold',
                        fontSize: '16px'
                    }}>
                        Operace byla úspěšná.
                    </p>
                    <p style={{
                        fontWeight: 'bold',
                        fontSize: '16px'
                    }}>
                        {successMessage}
                    </p>
                </div>
            )}
        </BrowserRouter>

    );
}

export default App;