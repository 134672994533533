import React, { useState, useEffect } from 'react';

const CookiesConsent = ({ onAccept }) => {
    const [showDialog, setShowDialog] = useState(localStorage.getItem('SeznamovakCookieConsent') !== 'true');

    const handleAccept = () => {
        localStorage.setItem('SeznamovakCookieConsent', 'true');
        setShowDialog(false);
    };

    useEffect(() => {
        if (!showDialog) {
            localStorage.setItem('SeznamovakCookieConsent', 'true');
        }
    }, [showDialog]);

    return (
        <>
            {showDialog && (
                <div style={{
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Polopriehľadný čierny overlay
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: '9999',
                }}>
                    <div style={{
                        backgroundColor: '#f0f0f0',
                        padding: '20px',
                        borderRadius: '8px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    }}>
                        <div style={{ textAlign: 'center' }}>
                            <p>Tato webová stránka používá soubory cookie k vylepšení uživatelského zážitku.</p>
                            <button style={{
                                backgroundColor: '#007bff',
                                color: '#fff',
                                border: 'none',
                                padding: '8px 16px',
                                borderRadius: '4px',
                                cursor: 'pointer',
                            }} onClick={handleAccept}>Souhlasím</button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default CookiesConsent;