

export const userStructure = {
                                    structure: {
                                        nickname: 'text',
                                        name: 'text',
                                        surname: 'text',
                                        intro: 'text',
                                        faculty_id: 'array',
                                        role_id: 'array',
                                        password: 'password',
                                        photo: 'file'
                                    },
                                    inicialData: {
                                        nickname: '',
                                        name: '',
                                        surname: '',
                                        intro: '',
                                        faculty_id: 1,
                                        role_id: 3,
                                        password: '',
                                        photo: ''
                                    },
                                    endPointStore: '/user',
                                    endPointEdit: '/user/',
                                    createButtonText: 'Vložit nového uživatele',
                                    editButtonText: 'Upravit uživatele',
                                    translator: {
                                        nickname: 'Přezdívka',
                                        name: 'Jméno',
                                        surname: 'Příjmení',
                                        intro: 'Predstavení',
                                        faculty_id: 'Fakulta',
                                        role_id: 'Role',
                                        password: 'Heslo',
                                        photo: 'Fotka'
                                    }
                                };
export const userProfileStructure = {
                                            structure: {
                                                name: 'text',
                                                surname: 'text',
                                                intro: 'text',
                                                faculty_id: 'array',
                                                password: 'password',
                                                photo: 'file'
                                            },
                                            endPointStore: '/user',
                                            endPointEdit: '/user/',
                                            editButtonText: 'Upravit můj profil',
                                            translator: {
                                                nickname: 'Přezdívka',
                                                name: 'Jméno',
                                                surname: 'Příjmení',
                                                intro: 'Predstavení',
                                                faculty_id: 'Fakulta',
                                                role_id: 'Role',
                                                password: 'Heslo',
                                                photo: 'Fotka'
                                            }
                                        };



export const customerStructure = {
                                        structure: {
                                            name: 'text',
                                            surname:'text' ,
                                            birthday: 'date',
                                            email:'text' ,
                                            phone:'text' ,
                                            gender: 'array',
                                            faculty: 'array',
                                            tour: 'array',
                                            transport: 'array',
                                            bonus: 'array',
                                        },
                                        structureShow: {
                                            ID: 'number',
                                            name: 'text',
                                            surname:'text' ,
                                            birthday: 'date',
                                            email:'text' ,
                                            phone:'text' ,
                                            state: 'array',
                                            gender: 'array',
                                            faculty: 'array',
                                            tour: 'array',
                                            transport: 'array',
                                            bonus: 'array',
                                            lodging: 'text',
                                            register_by: 'array',
                                        },
                                        inicialData: {
                                            name: '',
                                            surname:'' ,
                                            birthday: '',
                                            email:'' ,
                                            phone:'' ,
                                            gender: null,
                                            faculty: null,
                                            tour: null,
                                            transport: null,
                                            bonus: null,
                                        },
                                        endPointStore: '/customer',
                                        endPointEdit: '/user/',
                                        createButtonText: 'Vložit novou přihlášku',
                                        editButtonText: 'Vložit novou přihlášku',
                                        translator: {
                                            ID: 'ID',
                                            name: 'Jméno',
                                            surname: 'Příjmení',
                                            gender: 'Pohlaví',
                                            state: 'Stav',
                                            email: 'E-mail',
                                            phone: 'Telefon',
                                            faculty: 'Fakulta',
                                            tour: 'Turnus',
                                            transport: 'Doprava',
                                            bonus: 'Bonus',
                                            lodging: 'Ubytování',
                                            register_by: 'Registroval',
                                            birthday: 'Datum narození'
                                        }
                                };