import { useEffect } from 'react';
import { logout } from '../utils/auth';

import  { Navigate } from 'react-router-dom'


const Logout = () => {
    useEffect(() => {
        logout();
    }, []);

    return <Navigate to="/" />;
};

export default Logout;