import { Navigate } from 'react-router-dom';
import { useAuthStore } from '../store/auth';
import {isAccessTokenExpired} from "../utils/auth";

const PrivateRoute = ({ children }) => {
    const loggedIn = useAuthStore((state) => state.isLoggedIn)();

    if (!loggedIn)
    {
        return <Navigate to="/login" />
    }
    if (isAccessTokenExpired()) {
        return <Navigate to="/logout" />
    }
    else
    {
        return <>{children}</>
    }

};

export default PrivateRoute;