
import { postData, putData} from "../utils/arth";
import React, { useState} from "react";
import {Button, Dialog, DialogActions, DialogContent, DatePicker, TextField} from "@mui/material";



const Formular = ({structure,inicialData,selects,endPointURL,ButtonText,translator,dataSend,apiMethod,showError,showSuccess}) => {
    const [open, setOpen] = useState(false);
    const [outData, setOutData] = useState(inicialData);

    console.log(outData);
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        if(structure[name] === 'file')
        {
            const file = e.target.files[0];
            setOutData({ ...outData, [name]: file });
        }
        else
        {
            setOutData({ ...outData, [name]: value });
        }

    };

    const handleSave = async () => {
        try {
            if(apiMethod == 'POST')
            {
                await postData(endPointURL, outData);
            }
            else if(apiMethod == 'PUT')
            {
                await putData(endPointURL, outData);
            }
            handleClose();
            dataSend();
        } catch (error) {
            showError(error)
            console.error('Chyba při ukládání dat:', error);
        }
    };


    return (
        <>
            <Button variant="contained" color="primary" onClick={handleOpen}>{ButtonText}</Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    {Object.entries(structure).map(([key, value]) => (

                        <div key={key} style={{ marginBottom: '1rem', display: 'flex', alignItems: 'center' }}>
                            <label htmlFor={key} style={{ marginRight: '0.5rem', flex: '1' }}>{translator[key]}:</label>
                            {value === 'array' ? (
                                <select
                                    id={key}
                                    name={key}
                                    value={outData[key] || ""}
                                    onChange={handleChange}
                                    style={{ padding: '0.5rem', borderRadius: '4px', border: '1px solid #ccc', flex: '2' }}
                                >
                                    <option value="" disabled={inicialData[key] !== null} selected>Vyber hodnotu</option>
                                    {selects[key].map((option) => (
                                        <option key={option.ID} value={option.ID}>{option.name}</option>
                                    ))}
                                </select>
                            ) : value === 'number' ?(
                                <input
                                    type="number"
                                    id={key}
                                    name={key}
                                    value={outData[key]}
                                    onChange={handleChange}
                                    style={{ padding: '0.5rem', borderRadius: '4px', border: '1px solid #ccc', flex: '2' }}
                                />
                            ) :  value === 'date' ? (
                                <input
                                    type="date"
                                    id={key}
                                    name={key}
                                    value={outData[key]}
                                    onChange={handleChange}
                                    style={{ padding: '0.5rem', borderRadius: '4px', border: '1px solid #ccc', flex: '2' }}
                                />
                            ): value === 'text' ?(
                                <TextField
                                    id={key}
                                    name={key}
                                    value={outData[key]}
                                    onChange={handleChange}
                                    variant="outlined"
                                    fullWidth
                                    multiline={true}
                                    style={{ padding: '0.5rem', flex: '2' }}
                                />
                            ) : value === 'password' ?(
                                <input
                                    type="password"
                                    id={key}
                                    name={key}
                                    onChange={handleChange}
                                    style={{ padding: '0.5rem', borderRadius: '4px', border: '1px solid #ccc', flex: '2' }}
                                />
                            ) : (
                                    <input
                                        type="file"
                                        id={key}
                                        name={key}
                                        onChange={handleChange}
                                        style={{ padding: '0.5rem', borderRadius: '4px', border: '1px solid #ccc', flex: '2' }}
                                    />
                            )}
                        </div>
                    ))}
                </DialogContent>.
                <DialogActions>
                    <Button onClick={handleSave} color="primary" variant="contained">Ulozit</Button>
                    <Button onClick={handleClose} color="secondary" variant="contained">Zrusit</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};


export default Formular;