import {Navigate, useNavigate} from 'react-router-dom';
import { useAuthStore } from '../store/auth';
import {deleteData, getData} from "../utils/arth";
import React, {useEffect, useState} from "react";
import {
    Button,
    MenuItem,
    Select,
    TextField,
    CircularProgress,
    FormControl,
    InputLabel,
    Paper
} from "@mui/material";
import logo from '../seznamovak_logo.svg';
import { userStructure} from '../utils/dataStructures';
import Box from "@mui/material/Box";
import Formular from "./formular";

const Users = ({showError,showSuccess}) => {

    const [isLoggedIn, user] = useAuthStore((state) => [
        state.isLoggedIn,
        state.user
    ]);
    const navigate = useNavigate();
    const haveCompetencesRead = user().competences.includes('user_read');
    const haveCompetencesWrite = user().competences.includes('user_write');
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [reload, setReload] = useState(0);
    const [selectedRole, setSelectedRole] = useState('');
    const [selectedFaculty, setSelectedFaculty] = useState('');
    const [roles, setRoles] = useState([]);
    const [faculties, setFaculties] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selects, setSelects] = useState({});
    const [searchText, setSearchText] = useState('');

    const handleSearchTextChange = (event) =>
    {
        setSearchText(event.target.value);
    }

    const handleSelectedRoleChange = (event) =>{
        setSelectedRole(event.target.value);
    };

    const handleSelectedFacultyChange = (event) =>{
        setSelectedFaculty(event.target.value);
    };

    const handleDelete = async (id) => {

        try {
            await deleteData(`/user/${id}`);
            setReload(reload + 1);
        } catch (error) {
            console.error('Chyba při mazání uživatele:', error);
        }
    };

    const handleReload = () => {
        setReload(reload + 1);
        setIsLoading(true);
    }

    const normalizeString = (str) => {
        return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase();
    };

    const filterUsers = () => {
        let filtered = users;
        if (selectedRole) {
            filtered = filtered.filter(user => user.role.ID === selectedRole);
        }
        if (selectedFaculty) {
            filtered = filtered.filter(user => user.faculty && user.faculty.ID === selectedFaculty);
        }
        if (searchText) {
            const lowercasedsearchText = normalizeString(searchText);
            filtered = filtered.filter(user =>
                normalizeString(user.name).startsWith(lowercasedsearchText) ||
                normalizeString(user.surname).startsWith(lowercasedsearchText) ||
                normalizeString(user.nickname).startsWith(lowercasedsearchText) ||
                normalizeString(user.name + ' ' + user.surname).startsWith(lowercasedsearchText)
            );
        }
        setFilteredUsers(filtered);
    };

    useEffect(() => {
        if (!isLoggedIn()) {
            navigate('/login');
        } else if (!haveCompetencesRead) {
            navigate('/');
        }
        async function fetchData() {
            try {
                const [status, result] = await getData('/user');
                if(status != 200)
                {
                    showError(result.error);
                    return  <Navigate to="/" />;
                }
                else
                {
                    const time = Math.floor(new Date().getTime() / 10000);
                    setUsers(result.users.map(user => ({
                        ...user,
                        photo_url: `${user.photo_url}?timestamp=${time}`
                    })));
                    setRoles(result.formatRoles);
                    setFaculties(result.formatFaculties);
                    setSelects({...selects, faculty_id: result.formatFaculties, role_id: result.formatRoles});
                    setIsLoading(false);
                }
            } catch (error) {
                console.error('Chyba pri získavaní dát:', error);
            }
        }

        fetchData();
    }, [reload]);

    useEffect(() => {
        filterUsers();
    }, [users, selectedRole, selectedFaculty,searchText]);

    return (
        <div>
            <div style={{ marginBottom: '20px' }}>
                <h1 style={{ fontSize: '36px', fontWeight: 'bold', color: '#111' }}>Seznam uživatelů</h1>
            </div>
            {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                    <CircularProgress />
                </div>
            ) : (
                <>
                {haveCompetencesWrite &&<div style={{ marginBottom: '20px', marginLeft: '20px' }}>
                        <Formular
                            key={JSON.stringify(selects)}
                            structure={userStructure.structure}
                            inicialData={userStructure.inicialData}
                            selects={selects}
                            endPointURL={userStructure.endPointStore}
                            ButtonText={userStructure.createButtonText}
                            translator={userStructure.translator}
                            dataSend={handleReload}
                            apiMethod={'POST'}
                            showError={showError}
                            showSuccess={showSuccess}
                        />
                    </div>}
                    <div>
                        <Paper elevation={3} style={{ maxWidth: '1000px', padding: '20px', marginBottom: '20px' }}>
                            <TextField
                                label="Vyhledat uživatele"
                                variant="outlined"
                                value={searchText}
                                onChange={handleSearchTextChange}
                                style={{ marginBottom: '20px', width: '100%' }}
                            />
                            <Box display="flex" justifyContent="space-between">
                                <FormControl variant="outlined" style={{ flex: 1, marginRight: '10px' }}>
                                    <InputLabel>Rola</InputLabel>
                                    <Select
                                        value={selectedRole}
                                        onChange={handleSelectedRoleChange}
                                        label="Role"
                                    >
                                        <MenuItem value="">Všechny role</MenuItem>
                                        {roles.map(role => (
                                            <MenuItem key={role.ID} value={role.ID}>{role.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl variant="outlined" style={{ flex: 1 }}>
                                    <InputLabel>Fakulta</InputLabel>
                                    <Select
                                        value={selectedFaculty}
                                        onChange={handleSelectedFacultyChange}
                                        label="Fakulty"
                                    >
                                        <MenuItem value="">Všechny fakulty</MenuItem>
                                        {faculties.map(faculty => (
                                            <MenuItem key={faculty.ID} value={faculty.ID}>{faculty.name}</MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Paper>
                    </div>
                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                        {filteredUsers.map((user, index) => (
                            <div key={user.ID} style={{ maxWidth: '300px', width: '50%', padding: '0.5rem', boxSizing: 'border-box' }}>
                                <div className="div-block-26" style={{ border: '1px solid #ddd', borderRadius: '8px', padding: '1rem' }}>
                                    <div
                                        className="div-block-27"
                                        style={{
                                            backgroundImage: `url(${user.photo_url || logo})`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            height: '200px',
                                            borderRadius: '8px 8px 0 0'
                                        }}
                                    ></div>
                                    <div className="div-block-28" style={{ padding: '1rem', textAlign: 'center' }}>
                                        <h3 className="prezdivka" style={{ margin: '0 0 0.5rem' }}>{user.nickname}</h3>
                                        <p style={{ margin: '0 0 0.5rem', color: '#555' }}>{user.name} {user.surname}</p>
                                        <p style={{ margin: '0 0 0.5rem', color: '#555' }}>{user.role.name_cz}</p>
                                        <p style={{ margin: '0 0 0.5rem', color: `${user.faculty?.color}` }}>{user.faculty?.name}</p>
                                        <p style={{ margin: '0 0 0.5rem', color: `${user.faculty?.university.color}` }}>{user.faculty?.university.name}</p>
                                        <img src={user.photo_url || logo} alt={`${user.nickname}'s profile`} style={{ borderRadius: '50%', width: '100px', height: '100px', objectFit: 'cover' }}/>
                                        { user.intro && <div style={{ position: 'relative', maxWidth: '100%', marginBottom: '20px' }}>
                                            <div style={{ position: 'absolute', top: '10px', left: '-10px', width: '0', height: '0', borderStyle: 'solid', borderWidth: '10px 10px 0 0', borderColor: 'transparent transparent transparent #40E0D0' }}></div>
                                            <div style={{ backgroundColor: '#ffffff', borderRadius: '10px', padding: '10px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', border: '2px solid #40E0D0' }}>
                                                <p style={{ margin: '0', color: '#777' }}>{user.intro}</p>
                                            </div>
                                        </div>}
                                        <div style={{ marginTop: '1rem' }}>
                                            {haveCompetencesWrite &&
                                                <div style={{marginBottom: '20px'}} >

                                                    <Formular
                                                        key={JSON.stringify(selects)}
                                                        structure={userStructure.structure}
                                                        inicialData={{...user,password: ''}}
                                                        selects={selects}
                                                        endPointURL={userStructure.endPointEdit + `${user.ID}`}
                                                        ButtonText={userStructure.editButtonText}
                                                        translator={userStructure.translator}
                                                        dataSend={handleReload}
                                                        apiMethod={'PUT'}
                                                        showError={showError}
                                                        showSuccess={showSuccess}
                                                    />
                                                </div>
                                            }

                                            {haveCompetencesWrite && <Button variant="contained" color="primary" onClick={() => handleDelete(user.ID)}>Odstranit uživatele</Button>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            )}
        </div>
    );
};

export default Users;