
import { GridExample } from '../utils/table2';
import React, {useEffect, useState} from "react";
import {EMPTY_TABLE_TYPE, SETTINGS_TABLE_TYPE} from "../utils/constants";
import {getData, postData} from '../utils/arth';
import {Button, Dialog, DialogActions, DialogContent} from "@mui/material";



const AddData = ({type}) => {

    const [inputStruct, setInputStruct] = useState([]);
    useEffect(() => {
        async function fetchData() {
            try {
                const result = await getData('settings');
                setInputStruct(result);
                console.log(result);
            } catch (error) {
                console.error('Chyba pri získavaní dát:', error);
            }
        }

        fetchData(); // Spustenie funkcie na získanie dát
    },[]);
        const [open, setOpen] = useState(false);
        const [ inputData, setInputData] = useState({
            nickname: '',
            name: '',
            surname: '',
            role: ['Admin', 'Vedúci','Inštruktor'],
            password: '',
            intro: ''
        });
        const [formData, setFormData] = useState({
            nickname: '',
            name: '',
            surname: '',
            role: 'Admin',
            password: '',
            intro: ''
        });

        const handleOpen = () => {
            setOpen(true);
        };

        const handleClose = () => {
            setOpen(false);
        };

        function handleFileChange(event) {
            const file = event.target.files[0];
            setInputData({...inputData, photo: file});
            setFormData({ ...formData, photo: file });
        }

        const handleSave = () => {
            // Implementujte logiku pro uložení změn, např. volání API atd.
            // Zde můžete pracovat s formData, který obsahuje aktualizovaná data
            console.log('Uložit změny:', formData);
            const data = postData('/user',formData)
            console.log(data)
            handleClose();
        };

        // Funkce pro aktualizaci stavu formuláře
        const handleChange = (e) => {
            const { name, value } = e.target;
            if (! Array.isArray(inputData[name])) {
                setInputData({...inputData, [name]: value});
            }
            setFormData({ ...formData, [name]: value });
        };

        const translate = (key) => {
            if (key === 'name') {
                return 'Meno';
            }
            else if (key === 'nickname') {
                return 'Prezyvka';
            }
            else if (key === 'surname') {
                return 'Priezvisko';
            }
            else if (key === 'role') {
                return 'Rola';
            }
            else if (key === 'password') {
                return 'Heslo';
            }
            else if (key === 'intro') {
                return 'Popis';
            }
            return key;
        };

        return (
            <>
                <button
                    onClick={handleOpen}
                    style={{
                        padding: '10px 20px',
                        fontSize: '16px',
                        backgroundColor: '#007bff',
                        color: '#fff',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                        transition: 'background-color 0.3s',
                    }}
                >
                    Vložiť nového používateľa
                </button>
                <Dialog open={open} onClose={handleClose}>
                    <DialogContent>
                        {/* Sem vložte obsah formuláře úpravy */}
                        {Object.entries(inputData).filter(([key]) => key !== 'photo').map(([key, value]) => (

                            <div key={key} style={{ marginBottom: '1rem', display: 'flex', alignItems: 'center' }}>
                                <label htmlFor={key} style={{ marginRight: '0.5rem', flex: '1' }}>{translate(key)}:</label>
                                {Array.isArray(value) ? (
                                    <select
                                        id={key}
                                        name={key}
                                        value={formData["role"]} // Predpokladáme, že chcete vybrať prvú hodnotu ako predvolenú
                                        onChange={handleChange}
                                        style={{ padding: '0.5rem', borderRadius: '4px', border: '1px solid #ccc', flex: '2' }}
                                    >
                                        {value.map((option, index) => (
                                            <option key={index} value={option}>{option}</option>
                                        ))}
                                    </select>
                                ) : (
                                    <input
                                        type="text"
                                        id={key}
                                        name={key}
                                        value={value}
                                        onChange={handleChange}
                                        style={{ padding: '0.5rem', borderRadius: '4px', border: '1px solid #ccc', flex: '2' }}
                                    />
                                )}
                            </div>
                        ))}
                        {/* Kolonka na vloženie fotky */}
                        <div style={{ marginBottom: '1rem', display: 'flex', alignItems: 'center' }}>
                            <label htmlFor="photo" style={{ marginRight: '0.5rem', flex: '1' }}>Fotka:</label>
                            <input
                                type="file"
                                id="photo"
                                name="photo"
                                onChange={handleFileChange}
                                style={{ padding: '0.5rem', borderRadius: '4px', border: '1px solid #ccc', flex: '2' }}
                            />
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleSave} color="primary" variant="contained">Ulozit</Button>
                        <Button onClick={handleClose} color="secondary" variant="contained">Zrusit</Button>
                    </DialogActions>
                </Dialog>
            </>
        );
};




const Settings = ({ setError }) => {

    const [selectedOption, setSelectedOption] = useState(EMPTY_TABLE_TYPE);
    const [data, setData] = useState(null);

    useEffect(() => {
        if (selectedOption.en_name) {
            getData(selectedOption.url)
                .then((result) => {
                    setData(result);
                })
                .catch((error) => {
                    setError('Chyba pri načítaní dát: ' + error.message);
                });
        }
    }, [selectedOption]);

    const handleSelectChange = (e) => {
        const selectedValue = e.target.value;
        const selectedTable = SETTINGS_TABLE_TYPE.find(option => option.en_name === selectedValue);
        setSelectedOption(selectedTable || EMPTY_TABLE_TYPE);
    };



    return (

        <div >
            <div>
                <label htmlFor="table-select">Vyberte tabulku:</label>
                <br />
                <select
                    id="table-select"
                    value={selectedOption.en_name} // Použijte en_name jako unikátní identifikátor
                    onChange={handleSelectChange}
                    style={{
                        padding: '10px',
                        fontSize: '16px',
                        border: '1px solid #ccc',
                        borderRadius: '5px',
                        backgroundColor: '#fff',
                        marginBottom: '20px', // Přidá mezery pod select
                        width: '200px', // Určí šířku selectu
                    }}
                >
                    <option value={EMPTY_TABLE_TYPE.en_name} disabled hidden>Zvol tabulku</option>
                    {SETTINGS_TABLE_TYPE.map((option, index) => (
                        <option key={index + 1} value={option.en_name}>
                            {option.cz_name}
                        </option>
                    ))}
                </select>
            </div>
            <div>
                <div>
                    <AddData type={selectedOption} />
                </div>
                <div>
                    <GridExample data={data} editable={selectedOption.editable}  />
                </div>
            </div>
        </div>
    );
};

export default Settings;