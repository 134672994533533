import React, { useState } from 'react';

const Payment = () => {
    const [paymentData, setPaymentData] = useState({
        recipient: '',
        amount: '',
        description: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setPaymentData({ ...paymentData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Zde by měla být implementována logika pro zadání již provedené platby
        console.log('Zadaná platba:', paymentData);
        // Mohou se také vyčistit údaje formuláře
        setPaymentData({
            recipient: '',
            amount: '',
            description: ''
        });
    };

    return (
        <div>
            <h2>Zadejte již provedenou platbu</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="recipient">Příjemce:</label>
                    <input type="text" id="recipient" name="recipient" value={paymentData.recipient} onChange={handleChange} />
                </div>
                <div>
                    <label htmlFor="amount">Částka:</label>
                    <input type="number" id="amount" name="amount" value={paymentData.amount} onChange={handleChange} />
                </div>
                <div>
                    <label htmlFor="description">Popis:</label>
                    <textarea id="description" name="description" value={paymentData.description} onChange={handleChange}></textarea>
                </div>
                <button type="submit">Zadat platbu</button>
            </form>
        </div>
    );
};

export default Payment;