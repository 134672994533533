import { create } from 'zustand';
import { mountStoreDevtool } from 'simple-zustand-devtools';

const useAuthStore = create((set, get) => ({
    allUserData: null, // Use this to store all user data
    loading: false,
    user: () => ({
        user_id: get().allUserData?.sub || null,
        token: get().allUserData?.token || null,
        nickname: get().allUserData?.nickname || null,
        role: get().allUserData?.role || null,
        competences: get().allUserData?.competences.map(item => item.name_en) || null,
    }),
    setUser: (user) => set({ allUserData: user }),
    setLoading: (loading) => set({ loading }),
    isLoggedIn: () => get().allUserData !== null,
}));

if (import.meta.env) {
    mountStoreDevtool('Store', useAuthStore);
}

export { useAuthStore };