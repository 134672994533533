export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


export const SETTINGS_TABLE_TYPE = [

    {"cz_name" : "Bonus", "en_name": "bonus", "url": "/settings/bonus","editable": true},
    {"cz_name" : "Kompetencie", "en_name": "competence", "url": "/settings/competence","editable": true},
    {"cz_name" : "Fakulty", "en_name": "faculty", "url": "/settings/faculty","editable": true},
    {"cz_name" : "Pohlavie", "en_name": "gender", "url": "/settings/gender","editable": true},
    {"cz_name" : "Rola", "en_name": "role", "url": "/settings/role","editable": true}
]

export const EMPTY_TABLE_TYPE ={"cz_name" : "", "en_name": "", "url": "","editable": false}