import React, { useState } from 'react';

const ErrPopUp = ({ errorMessage, closeMessage }) => {

    const handleClose = () => {
        closeMessage();
    };

    return (

            <div style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                zIndex: 9999,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <div style={{
                    backgroundColor: 'white',
                    padding: '20px',
                    borderRadius: '5px',
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)',
                    maxWidth: '400px',
                }}>

                    <p style={{
                        marginBottom: '20px',
                        color: 'red',
                        fontWeight: 'bold',
                        fontSize: '16px'
                    }}>
                        {errorMessage}
                    </p>
                    <button style={{
                        padding: '10px 20px',
                        backgroundColor: '#007bff',
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                    }} onClick={handleClose}>Zavriet</button>
                </div>
            </div>

    );
};

export default ErrPopUp;