
import React, { useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import EditButtonRenderer from './button';

export const GridExample = ({ data, editable }) => {
    const [rowData, setRowData] = useState([]);
    const [colDefs, setColDefs] = useState([]);

    useEffect(() => {
        let newData = [];
        let newColDefs = [];

        if (data) {
            if (typeof data === 'object' && data.length > 0) {
                newData = data;
                const firstObj = data[0];
                newColDefs = Object.keys(firstObj).map((key) => {
                    if (key !== 'ID') {
                        return {
                            field: key,
                            editable: editable,
                            autoSize: true,
                        };
                    }
                    return null;
                }).filter(colDef => colDef !== null);

                // Přidejte definici sloupce pro tlačítko editace na konec pole newColDefs
                newColDefs.push({
                    headerName: 'Actions',
                    cellRenderer: EditButtonRenderer, // používáme jméno, které identifikuje náš renderer
                });
            } else {
                // Logika pro jiné formáty dat
            }
            setRowData(newData);
            setColDefs(newColDefs);
        }
    }, [data, editable]);

    const gridOptions = {
        domLayout: 'autoHeight',

        editType: 'fullRow',
        onCellValueChanged: (event) => {
            console.log('Hodnota buňky se změnila', event.data);
        },
        onRowValueChanged: (event) => {
            console.log('Hodnota řádku se změnila', event.data);
        },
        autoSizeStrategy: {
            type: "fitGridWidth",
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'row', height: '100%' }}>
        <div className="ag-theme-alpine"  style={{ overflow: 'hidden', flexGrow: 1 }}>
            {rowData.length > 0 &&    <AgGridReact
                rowData={rowData}
                columnDefs={colDefs}
                gridOptions={gridOptions}
            />}
        </div>
        </div>
    );
};