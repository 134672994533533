import axios from "axios";
import { API_BASE_URL } from "./constants";
import Cookies from "js-cookie";

const apiInstance = () => {
    // Získame JWT token
    const jwt = Cookies.get('Seznamovak_token');

    // Vytvoríme axios inštanciu s aktuálnym JWT tokenom v hlavičke Authorization

    return axios.create({
        baseURL: API_BASE_URL,
        timeout: 30000,
        headers: {
            'Content-Type': 'multipart/form-data',
            Accept: 'application/json',
            Authorization: jwt ? `Bearer ${jwt}` : '', // Pridáme JWT token do hlavičky Authorization, ak existuje
        },
    });
};




// Exportujeme funkciu na vytvorenie axios inštancie
export default apiInstance;