import React, {useEffect, useState} from 'react';
import './my_styles/profile.css'
import {getData} from "../utils/arth";
import {navigate} from "jsdom/lib/jsdom/living/window/navigation";
import {useAuthStore} from "../store/auth";
import {CircularProgress} from "@mui/material";
import logo from '../seznamovak_logo.svg';
import {userProfileStructure} from "../utils/dataStructures";
import Formular from './formular'



const Profile = ({showError, showSuccess}) => {
    const [isLoggedIn, user] = useAuthStore((state) => [
        state.isLoggedIn,
        state.user
    ]);
    const [profileData, setProfileData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [selects, setSelects] = useState({});
    const [reload,setReload]=useState(0);

    useEffect(() => {
        if (!isLoggedIn()) {
            navigate('/login');
        }
        async function fetchData() {
            try {
                const [status,result] = await getData(`/user/${user().user_id}`);
                if(status != 200)
                {
                    showError(result.errorMessage);
                }
                else
                {
                    const time = Math.floor(new Date().getTime() /10000) ;
                    setProfileData({
                        ...result.user,
                        photo_url: result.user.photo_url ? `${result.user.photo_url}?timestamp=${time}` : '',
                    });
                    setSelects({...selects, faculty_id: result.formatFaculties})
                    console.log(result.user);
                    setIsLoading(false);
                }

            } catch (error) {
                showError(error,true);
                console.error('Chyba pri získavaní dát:', error);
            }
        }

        fetchData();
    }, [reload]);

    const handleReload = () =>
    {
        setReload(reload+1);
    }


    return (
        <>
            <div style={{ marginBottom: '20px' }}>
                <h1 style={{ fontSize: '36px', fontWeight: 'bold', color: '#111' }}>Můj profil:</h1>
            </div>
            <div className="profile">
                {isLoading ? (
                    <>
                        <div>

                        </div>
                        <div className="info" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                            <CircularProgress />
                        </div>
                    </>
                ) : (
                    <>
                        <div className="photo">
                            {user.photo_url ? (
                                    <img src={user.photo_url || logo} alt={`${user.nickname}'s profile`} style={{ borderRadius: '50%', width: '200px', height: '200px', objectFit: 'cover' }}/>
                                ):(
                                    <span style={{ color: 'red' , width: '200px', height: '200px' }}>Bez fotky</span>
                                )}

                        </div>
                        <div className="info">
                            <h2 className="name">
                                { profileData.nickname}
                            </h2>
                            <p><strong>Jméno:</strong> {profileData.name}</p>
                            <p><strong>Příjmení:</strong> {profileData.surname}</p>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <p style={{ marginRight: '10px' }}><strong>Predstavení:</strong></p>
                                {profileData.intro ? (<div style={{ maxWidth: '150px', backgroundColor: '#ffffff', borderRadius: '10px', padding: '10px', boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)', border: '2px solid #40E0D0' }}>
                                    <p style={{ margin: '0', color: '#777' }}>
                                        {profileData.intro}
                                    </p>
                                </div>) :(<span style={{ color: 'red' }}>Bez popisu</span>)}

                            </div>

                            <div>
                                <p><strong>Rola:</strong> { profileData.role.name}</p>
                            </div>
                            <div>
                                <h3>Oprávnění:</h3>
                                <ul className="list">
                                    {profileData.competences.map((permission, index) => (
                                        <li key={index}>{permission.name}</li>
                                    ))}
                                </ul>
                            </div>

                        </div>
                        <Formular
                            key={JSON.stringify(profileData)}
                            structure={userProfileStructure.structure}
                            inicialData={{...profileData, password: ''}}
                            selects={selects}
                            endPointURL ={userProfileStructure.endPointEdit+`${user().user_id}`}
                            ButtonText={userProfileStructure.editButtonText}
                            translator={userProfileStructure.translator}
                            dataSend={handleReload}
                            apiMethod={'PUT'}
                            showError={showError}
                            showSuccess={showSuccess}
                        />
                    </>
                    )}
            </div>
        </>
    );
};
export default Profile;