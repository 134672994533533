import {useNavigate} from 'react-router-dom';
import { useAuthStore } from '../store/auth';
import { getData} from "../utils/arth";
import React, {useEffect, useState} from "react";
import {CircularProgress} from "@mui/material";
import Formular from "./formular";
import {customerStructure} from '../utils/dataStructures';
import Table from '../utils/table'





const Customers = ({showError,showSuccess}) => {

    const [isLoggedIn, user] = useAuthStore((state) => [
        state.isLoggedIn,
        state.user
    ]);
    const navigate = useNavigate();
    const haveCompetencesRead = user().competences.includes('customer_read');
    const haveCompetencesWrite = user().competences.includes('customer_write');
    const [selects,setSelects] = useState({});
    const [customers, setCustomers] = useState([]);
    const [reload,setReload] = useState(0);
    const [isLoading,setIsLoading] = useState(true);



    useEffect(() => {
            if (!isLoggedIn()) {
                navigate('/login');
            }
            else if(!haveCompetencesRead)
            {
                navigate('/');
            }
            const fetchData = async () => {


                const [status,result] = await getData('/customer');
                if(status != 200)
                {
                    showError(result.errorMessage);
                }
                else {
                    setCustomers(result.customers);
                    setSelects({
                        ...selects,
                        bonus: result.formatBonus,
                        faculty: result.formatFaculties,
                        gender: result.formatGenders,
                        state: result.formatStates,
                        tour: result.formatTours,
                        transport: result.formatTransports,
                    });
                }
                setIsLoading(false);
            };

            fetchData();
        }, [reload,isLoggedIn]);

    const handleAddedCustomer = () => {
        setReload(reload+1);
        setIsLoading(true);
    }

    return (

        <div>
            <div style={{ marginBottom: '20px' }}>
                <h1 style={{ fontSize: '36px', fontWeight: 'bold', color: '#111' }}>Seznam přihlášek</h1>
            </div>
            {isLoading ? (<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
                <CircularProgress />
            </div>) : (<>
                    <div style={{ marginBottom: '20px' ,marginLeft: '20px' }}>
                        {haveCompetencesWrite &&
                            <Formular
                                key={JSON.stringify(selects)}
                                structure={customerStructure.structure}
                                inicialData={customerStructure.inicialData}
                                selects={selects}
                                endPointURL={customerStructure.endPointStore}
                                ButtonText={customerStructure.createButtonText}
                                translator={customerStructure.translator}
                                dataSend={handleAddedCustomer}
                                apiMethod={'POST'}
                                showError={showError}
                                showSuccess={showSuccess}
                            />
                        }
                    </div>
                    <div style={{ marginBottom: '20px' ,marginLeft: '20px' }}>
                        <Table
                            key={JSON.stringify(customers)}
                            data={customers}
                            structureShow={customerStructure.structureShow}
                            translator={customerStructure.translator}
                            selectsShow={selects}
                            haveCompetenceWrite={haveCompetencesWrite}
                            showError={showError}
                            showSuccess={showSuccess}
                        />

                    </div>

            </>
        )}
        </div>

    );
};

export default Customers;