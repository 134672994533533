import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, Button } from '@mui/material';

const ButtonActionRenderer = ({ data }) => {
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState(data);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = () => {
        // Implementujte logiku pro uložení změn, např. volání API atd.
        // Zde můžete pracovat s formData, který obsahuje aktualizovaná data
        console.log('Uložit změny:', formData);
        handleClose();
    };

    // Funkce pro aktualizaci stavu formuláře
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    return (
        <>
            <button onClick={handleOpen}>Edit</button>
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    {/* Sem vložte obsah formuláře úpravy */}
                    {Object.entries(formData).map(([key, value]) => (
                        <div key={key} style={{ marginBottom: '1rem', display: 'flex', alignItems: 'center' }}>
                            <label htmlFor={key} style={{ marginRight: '0.5rem', flex: '1' }}>{key}:</label>
                            <input
                                type="text"
                                id={key}
                                name={key}
                                value={value}
                                onChange={handleChange}
                                style={{ padding: '0.5rem', borderRadius: '4px', border: '1px solid #ccc', flex: '2' }}
                            />
                        </div>
                    ))}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleSave} color="primary" variant="contained">Ulozit</Button>
                    <Button onClick={handleClose} color="secondary" variant="contained">Zrusit</Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ButtonActionRenderer;