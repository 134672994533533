import { useEffect, useState } from 'react';
import { login } from '../utils/auth';
import { useNavigate } from 'react-router-dom';
import { useAuthStore } from '../store/auth';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import {TextField, IconButton} from "@mui/material";
import Typography from '@mui/material/Typography';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import "@fontsource/roboto/300.css";
import CookiesConsent from '../utils/cookiesConsent'


const styles = {
  typography: {
    fontFamily: 'Roboto, sans-serif', // Set the font family to Roboto or your selected font
    fontSize: '3rem', // Adjust the font size according to your preference
    fontWeight: 1000, // You can adjust the font weight as well
    color: 'black', // Customize the text color
    marginTop: '5rem', // Add some top margin
    textAlign: 'center', // Center the text
    textTransform: 'uppercase', // Convert text to uppercase
    letterSpacing: '5px', // Add letter spacing
  },
};
const Login = () => {
    const navigate = useNavigate();
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const isLoggedIn = useAuthStore((state) => state.isLoggedIn)();
    const [showPassword, setShowPassword] = useState(false);

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };

    useEffect(() => {
        if (isLoggedIn) {
            navigate('/');
        }
    }, [isLoggedIn,navigate]);

    const resetForm = () => {
        setUsername('');
        setPassword('');
    };

    const handleLogin = async (e) => {
        e.preventDefault();
        const { error } = await login(username, password);
        if (error) {
            alert(error);
        } else {
            navigate('/');
            resetForm();
        }
    };
    return (
        <div style={{display: 'absolute'}}>
            <Typography variant="h2" sx={styles.typography}>
              Seznamovák Admin
            </Typography>

             <Box   display="flex"
                      justifyContent="center"
                      alignItems="center"
                      minHeight="50vh">
             <Stack spacing={2} sx={{ width: 300 }}>

                <h1>Login</h1>
                <form onSubmit={handleLogin}>
                     <TextField
                        label="Prihlasovací jméno"
                        onChange={e => setUsername(e.target.value)}
                        required
                        variant="outlined"
                        color="primary"
                        type="text"
                        sx={{mb: 3}}
                        fullWidth
                        value={username}
                     />
                     <TextField
                        label="Heslo"
                        onChange={e => setPassword(e.target.value)}
                        required
                        variant="outlined"
                        color="primary"
                        type={showPassword ? "text" : "password"}
                        sx={{mb: 3}}
                        fullWidth
                        value={password}
                        InputProps={{
                            endAdornment: (
                                <IconButton
                                    onClick={toggleShowPassword}
                                    onMouseDown={e => e.preventDefault()}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                                </IconButton>
                            ),
                        }}
                        inputProps={{
                            autoComplete: "new-password",
                            form: {
                                autoComplete: "off",
                            },
                        }}
                     />
                    <Button variant="contained" type="submit" mt={20} >Prihlásit</Button>
                </form>
             </Stack>
             </Box>
             <CookiesConsent/>
        </div>
    );
};

export default Login;