import React, {useMemo, useState} from 'react';
import {FaMinus, FaSortDown, FaSortUp} from "react-icons/fa";

const Table = ({ data, structureShow,translator,selectsShow,showError,showSuccess }) => {


    const [sortColumn, setSortColumn] = useState('');
    const [sortDirection, setSortDirection] = useState('');
    const [searchText, setSearchText] = useState({});
    const [selectSort, setSelectSort] = useState(Object.keys(selectsShow).reduce((acc, key) => {
        acc[key] = '';
        return acc;
    }, {}));
    const [currentPage, setCurrentPage] = useState(1);
    const [recordsPerPage] = useState(50);





    const headers = Object.keys(data[0]).filter((header) => Object.keys(structureShow).includes(header));

    const handleSortClick = (column) => {
        if (sortColumn === column) {
            setSortDirection((prev) => (prev === 'asc' ? 'desc' : 'asc'));
        } else {
            setSortColumn(column);
            setSortDirection('asc');
        }
    };

    const  handleSelectSortChange = (event, header) =>{
        setSelectSort({...selectSort, [header]: event.target.value});

    }

    const handleSearchTextChange = (event, column) => {
        const updatedSearchText = { ...searchText, [column]: event.target.value };
        setSearchText(updatedSearchText);
    };

    const sortedData = useMemo(() => {
        const sortedDataCopy = [...data];
        if (sortColumn) {
            sortedDataCopy.sort((a, b) => {
                const aValue = a[sortColumn];
                const bValue = b[sortColumn];
                if (sortDirection === 'asc') {
                    return aValue < bValue ? -1 : 1;
                } else {
                    return aValue > bValue ? -1 : 1;
                }
            });
        }
        return sortedDataCopy;
    }, [data, sortColumn, sortDirection]);

    const filteredData = useMemo(() => {
        return sortedData.filter((row) => {
            return Object.entries(searchText).every(([header, value]) => {
                if (!value) return true;
                const cellValue = row[header];
                return cellValue && cellValue.toString().toLowerCase().includes(value.toLowerCase());
            })&& Object.entries(selectSort).every(([header, value]) => {
                if (header === 'register_by')
                {
                    if (value === '') {  return true;}
                    if (value === (-2).toString()) { return row[header+'_id'] === null;}
                    if (value === (-1).toString()) {return row[header+'_id'] > 0;}
                }
                if (!value) return true;
                const cellValue = row[header+'_id'];
                return cellValue && cellValue.toString() === value.toString();
            });
        });
    },  [sortedData, searchText,selectSort]);

    const indexOfLastRecord = currentPage * recordsPerPage;
    const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    const currentRecords = filteredData.slice(indexOfFirstRecord, indexOfLastRecord);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const sortIndicator = (column) => {
        if (column === sortColumn) {
            return sortDirection === 'asc' ? <FaSortDown /> : <FaSortUp />;
        }
        return <FaMinus />;
    };

    if (!data || data.length === 0) {
        return <p>Žádné údaje k zobrazení.</p>;
    }

    return (
        <div>
            <table style={{ width: '100%', borderCollapse: 'collapse', borderRadius: '8px', overflow: 'hidden' }}>
                <thead style={{ backgroundColor: '#f0f0f0', borderBottom: '1px solid #ddd' }}>
                <tr>
                    {headers.map((header) => (
                        <th key={header} style={{ borderRight: '1px solid #ddd', borderBottom: '1px solid #ddd', padding: '10px', textAlign: 'left' }}>
                            <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row' }}>
                                    <span style={{ marginRight: '5px', fontWeight: 'bold' }}>{translator[header]}</span>
                                    {structureShow[header] === 'number' && (
                                        <div onClick={() => handleSortClick(header)} style={{ cursor: 'pointer', marginLeft: '5px' }}>
                                            {sortIndicator(header)}
                                        </div>
                                    )}
                                </div>
                                {structureShow[header] === 'text' && (
                                    <input
                                        style={{ width: '80%', padding: '6px', marginTop: '5px', borderRadius: '5px', border: '1px solid #ddd', textAlign: 'center' }}
                                        type="text"
                                        value={searchText[header]}
                                        onChange={(event) => handleSearchTextChange(event, header)}
                                        placeholder={`Hledat ${translator[header]}`}
                                    />
                                )}
                                {structureShow[header] === 'array' && (
                                    <select
                                        style={{ minWidth: '80%', padding: '6px', marginTop: '5px', borderRadius: '5px', border: '1px solid #ddd', textAlign: 'center' }}
                                        value={selectSort[header]}
                                        onChange={(event) => handleSelectSortChange(event, header)}
                                    >
                                        <option value="">Všechny</option>
                                        {header === 'register_by' && <option value={-1}>Instruktor</option>}
                                        {header === 'register_by' && <option value={-2}>Zájemce</option>}
                                        {selectsShow[header]?.map((option) => (
                                            <option key={option.ID} value={option.ID}>{option.name}</option>
                                        ))}
                                    </select>
                                )}
                            </div>
                        </th>
                    ))}
                    <th style={{ borderRight: '1px solid #ddd', borderBottom: '1px solid #ddd', padding: '10px', textAlign: 'center' }}>Akcie</th>
                </tr>
                </thead>
                <tbody>
                {currentRecords.map((row, rowIndex) => (
                    <tr key={rowIndex}>
                        {headers.map((header) => (
                            <td key={header} style={{ borderRight: '1px solid #ddd', borderBottom: '1px solid #ddd', padding: '10px', textAlign: 'left' }}>

                                {row[header]}
                            </td>
                        ))}

                    </tr>
                ))}
                </tbody>
            </table>
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '10px' }}>
                {Array.from({ length: Math.ceil(filteredData.length / recordsPerPage) }, (_, i) => (
                    <button key={i} onClick={() => paginate(i + 1)} style={{ margin: '0 5px' }}>
                        {i + 1}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default Table;